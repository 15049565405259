module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NCVC7P5","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CloudOfertas","short_name":"CloudOfertas","start_url":"/","background_color":"#15618C","theme_color":"#15618C","display":"minimal-ui","icon":"src/images/favicon-512x512.png","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4651e99352647d1163e1e86c76a80aec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"b2ebaa64-8939-421d-819f-7668d006ff6d","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZmN2Y4YTYyLTY5YmMtNGI3Zi05YmY5LWJmYjcwMjk2MmUwZiIsInVzZXIiOiJjODJjNDg1NS1kNDVkLTQ1MDItOGQwOC0zM2EzNTliNGUzMTAiLCJzaXRlIjoiYjJlYmFhNjQtODkzOS00MjFkLTgxOWYtNzY2OGQwMDZmZjZkIiwiaWF0IjoxNjQ2NzY0ODQ0fQ.MvazopsUJZ99VkEbXpNtZ_Ivt9HGqrvGTR_KpU87xUM"},"prd":{"siteId":"b2ebaa64-8939-421d-819f-7668d006ff6d","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZmN2Y4YTYyLTY5YmMtNGI3Zi05YmY5LWJmYjcwMjk2MmUwZiIsInVzZXIiOiJjODJjNDg1NS1kNDVkLTQ1MDItOGQwOC0zM2EzNTliNGUzMTAiLCJzaXRlIjoiYjJlYmFhNjQtODkzOS00MjFkLTgxOWYtNzY2OGQwMDZmZjZkIiwiaWF0IjoxNjQ2NzY0ODQ0fQ.MvazopsUJZ99VkEbXpNtZ_Ivt9HGqrvGTR_KpU87xUM"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
